<!-- APDS - Contrato de Servicios -->
<template>
  <div class="apds_contrato">

    <!-- Cabecera -->
    <div class="cabecera">
      <base_Header
        v-if="Entorno.header.header"
        :Entorno="Entorno.header"
        @onEvent="$emit('onEvent', { accion:'cerrar' })">
      </base_Header>
    </div>

    <v-sheet :elevation="4">

    <div class="contenedor px-2" style="max-width:1150px;">
        <div class="columna px-2">          
            
            <div class= "row fila">                
                <div class="col-md-12 text">
                    Estimado colaborador:<br>
                    Debido a la adecuación de las exigencias normativas que resultan de aplicación a esta relación contractual, le ponemos a disposición por medio del siguiente proceso, 
                    que <b>A.F.S., S.A.</b> y <b>{{ APD.name }}</b>, con NIF <b>{{ APD.nif }}</b>, formalizan el presente CONTRATO de prestación de servicios. <br>
                    Este documento se compone de:
                    <ul>
                        <li>Contrato de 8 páginas</li>    
                        <li>Anexo I: Tarifa</li>    
                        <li>Anexo II: Declaración Jurada de estar al corriente en el cumplimiento de las obligaciones tributarias con la Administración Pública y con la Seguridad Social.</li>    
                        <li>Anexo III: Acuerdo para el tratamiento de datos profesionales y de carácter profesional compuesto por 24 páginas, incluidos los subanexos I, II y III.</li>    
                    </ul>
                    Este contrato puede revisarlo desde el siguiente enlace: 
                    
                    <v-btn rounded @click="borrador">Borrador</v-btn> 

                    <span class="t9">
                        * Debe habilitar en su navegador que permita mostrar ventanas emergentes o popups, deberá permitirlo siempre para esta página.
                    </span>                    
                    <p class="mt-3 negrita">Y en prueba de conformidad, ambas partes firman el presente CONTRATO por duplicado, el cual será enviado por correo electrónico.</p>
                </div>               
            </div>
            

            <firma_canvas tip="2" :exp="0" :apd="apd" @onEvent="evtFirma" />

        </div>
      </div>
    </v-sheet>
  </div>
</template>



<script>

  import plugs from "@/common/general_plugs";
  const base_Header = () => plugs.groute("base_Header.vue", "base");
  const firma_canvas = () => plugs.groute("firma_canvas.vue", "comp");

  export default {
    components: { base_Header, firma_canvas },
    props: {
      padre: { type:String, default: '' },
      accionRow: { type:Object, default: null},
      apd: { type:String, default: '0'},
      componenteTipo: { type:String, default:'F' },
      Entorno: { type:Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.F)); }}
    },

    data() {
      return {
        schema: {
          ctrls: {}
        },
        api:'',
        stName:'stFapds_contrato',
        btra_permisos:{ extra:{}},

        logo: 'afs',        

        dialog:false


        //canvas

      };
    },

    computed: {

        APD() {
            return this.accionRow;
        },

        mapfre() {
            return true; //(this.record.callemp=='83');
        },

    },

    created() {

        this.ini_data();
    },


    methods: {
      ini_data() {

        // configuro Entorno
        this.Entorno.header.titulo = "APD - CONTRATO PRESTACIÓN DE SERVICIOS";

      },


      // obtengo los registros a mostrar
      async ini_component() {

        /* let args = { tipo:'fnc', accion: 'apds', fn_args: {
          accion:'liquidaciones',
          fnt:'lista'
        }};

        let apiResult = await this.$store.dispatch("ajaxRequest", { args:args });
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }

        // guardo records del Grid todos los arrays recibidos, en 'recordsDetalle'
        this.$store.commit(this.stName + '/update_records', apiResult.r[0]);
        this.$store.commit(this.stName + '/data2State', { prop:'recordsDetalle', value: apiResult.r });  */
      },

      // eventos de firma canvas
      evtFirma() {          
          this.$emit('onEvent', { accion: 'evtFirma' });
      },

      async borrador() {
                        
        var args = { tipo:'fnc', accion: 'apds', 
          fn_args: { 
            accion: 'contrato_pdf', 
            apd:this.APD.id,
            tpd: 1,
          }
        }             
        console.log('** borrador: ', args);
        let apiResult = await this.$store.dispatch("ajaxRequest", { args: args });  
        
        if (apiResult.sql.error) {
            this.$root.$alert.open(apiResult.sql.msg, 'error');
            return;
        }

        window.open(this.$store.state.G.url_tmp + apiResult.r[0], "_blank");

        //cerramos y salimos
        //this.$emit('onEvent', { accion:'firmado' });
              
      },

    }
  };
</script>


<style scoped>

/* @font-face {
	font-family: 'Century Gothic';
	src: url('fonts/century_gothic.eot');
	src: url('fonts/century_gothic.eot') format('embedded-opentype'), url('fonts/century_gothic.ttf') format('truetype');
}
*/

body {
	background: #FFFFFF;
	color: #000000;
	font-family:'Century Gothic',Geneva, Arial, Helvetica, sans-serif;
}
p {
	text-align: justify;
	font-size: 13px;
}
li {
	text-align: left;
	font-size: 13px;
}
h4 {
	text-align: left;
	font-size: 15px;
	font-weight: bold;
	text-decoration: underline;
}
h5 {
	text-align: left;
	font-size: 14px;
	font-weight: bold;
	text-decoration: underline;
}
input[type="checkbox"][readonly] {
  pointer-events: none;
}
.container {
  overflow: hidden;
}
/*.input.checkbox {
  disabled: true;
}*/

.img_fondo {
  position: absolute;
  opacity: 0.3;
  height: 700px;
  top: 22%;
  left: 60%;
}

.t9{ font-size: 10px; }
.t10{ font-size: 11px; }
.t11{ font-size: 11px; }
.t12{ font-size: 12px; }
.t13{ font-size: 14px; }
.t14{ font-size: 14px; }
.t15{ font-size: 15px; }
.t16{ font-size: 16px; }
.t17{ font-size: 17px; }
.t18{ font-size: 18px; }

.verde{ color: #1b6018; }
.negrita{ font-weight: bold; }
.centrado {text-align: center; }

.opcion {
	font-size: 15px;
	font-weight: bold;
	color: #1b6018;
}
.text {
	font-size: 14px;
	color: #1b6018;
}
.dato {
	font-size: 16px;
	font-weight: bold;
	color: #000000;
}
.dato2 {
	font-size: 14px;
	font-weight: bold;
	color: #000000;
}

.img_cabecera {
  margin-top: 1em;
  margin-bottom: 1.5em;
}

.txt_cabecera {
  border: 2px solid #1b6018;
  margin-top: 0.5em;
  text-align: center;
  font-size: 22px;
  font-weight: bold;
  color: #1b6018;
}
.p1 {
	line-height: 60px;
}

.input { background-color: rgba(0, 0, 0, 0); border:none; }

.fila{
  border: 1px solid #1b6018;
  border-left: 2px solid #1b6018;
  border-right: 2px solid #1b6018;
}

.primera_col {
  /* padding-bottom: 220px; */
  /* margin-bottom: -220px; */

  border-right: 2px solid #1b6018;
  text-align: center;
  font-size: 15px;
  color: #1b6018;
}

.primera_fila {
  border-top: 2px solid #1b6018;
}

.ultima_fila {
  border-top: 1px solid #1b6018;
  border-right: 2px solid #1b6018;
  height: 300px;
}


.tanatorio {
  border-left: 2px solid #1b6018;
}

.traslado {
  height: 100px;
  border-left: 2px solid #1b6018;
}

.fondo_input {
  background-color: #ffff00;
  background-color: rgba(255,255,0,0.2);
}
.inputs {
  border: none;
  background: none;
  width: 100%;
}

#cuadro_firma{
	display: none;
    position: absolute;
    top: 90px;
    left: 30px;
    right: 0px;
    bottom: 46px;
    overflow:hidden;
    /*background-color:#ddd;*/
	width: 800px;
	height: 400px;
	border: 3px solid #1b6018;
}
#firma{
    cursor:crosshair ;
    background-color:#eee;
}

</style>